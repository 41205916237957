import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class AssemblyOrdersProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('assembly-orders', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`assembly-orders/${id}`)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('assembly-orders', payload)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`assembly-orders/${payload.id}`, payload)
  }

  approveItem (id) {
    this.setHeader(getAuthToken())
    return this.put(`assembly-orders/${id}/approve`)
  }

  cancelItem (id) {
    this.setHeader(getAuthToken())
    return this.put(`assembly-orders/${id}/cancel`)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`assembly-orders/${id}`)
  }
}

export default AssemblyOrdersProvider
